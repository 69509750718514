import React, { useState, useRef } from 'react';
import { object, arrayOf, func, string } from 'prop-types';
import classNames from 'classnames';
import { ProductPodGroupContext } from './ProductPodGroupContext';
import { CustomProductPodDetails } from './CustomProductPodDetails';

const ProductPodGroup = ({
  children,
  customScheme,
  DetailsPod,
  keyword,
  detailsPodClassName
}) => {

  const nodeRef = useRef(null);

  const productPods = children.filter((child) => {
    return Array.isArray(child);
  })?.[0] || children;

  const [similarItems, setSimilarItems] = useState({
    itemId: null,
    position: null,
    reference: null,
    endOfRow: false,
    spliceIndex: null,
    closed: false
  });
  const viewSimilarButton = 'similar-item__selected';
  const viewSimilarLink = 'similar-items-link__caret--collapsed';

  let scheme = customScheme;
  if (!scheme) {
    scheme = keyword && keyword.length > 0 ? 'PLP_Search_In_Stock' : 'PLP_Browse_In_Stock';
  }

  const getIndexAtEndOfCurrentRow = (productPodRef, position) => {
    const currentPodContainer = productPodRef?.current?.parentNode;
    let indexAtEndOfCurrentRow;
    const podGrid = currentPodContainer?.parentNode;
    const numberOfPodsInRow = Math.round(podGrid?.offsetWidth / currentPodContainer?.offsetWidth);
    const currentPodIndexInRow = Math.round(currentPodContainer?.offsetLeft / currentPodContainer?.offsetWidth);

    indexAtEndOfCurrentRow = (numberOfPodsInRow - currentPodIndexInRow) + position - 1;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(indexAtEndOfCurrentRow)) indexAtEndOfCurrentRow = -1;
    if (indexAtEndOfCurrentRow > (productPods.length - 1)) indexAtEndOfCurrentRow = productPods.length - 1;

    return indexAtEndOfCurrentRow;
  };

  const getSpliceIndex = (newItemId, endOfRow) => {
    return productPods.findIndex((child, index) => newItemId && endOfRow === index);
  };

  const removeSimilarBtnClass = () => {
    const similarButton = document.querySelector(`.${viewSimilarButton}`);
    const similarLink = document.querySelector(`.${viewSimilarLink}`);
    const similarBtnClassList = similarButton?.classList;
    const similarLinkClassList = similarLink?.classList;
    if (similarBtnClassList) {
      similarBtnClassList.remove(viewSimilarButton);
    }
    if (similarLinkClassList) {
      similarLinkClassList.remove(viewSimilarLink);
    }
  };

  const handleButtonSelection = (position, productPodRef) => {
    removeSimilarBtnClass();
    const selectedButton = productPodRef.current.getElementsByClassName('bttn-outline')[0]?.classList;
    const similarItemClasses = classNames({ 'similar-item__selected': position !== similarItems.position });
    const selectedLink = productPodRef.current.getElementsByClassName('similar-items-link__caret')[0]?.classList;
    const similarLinkCollapsedClass = classNames({
      'similar-items-link__caret--collapsed': position !== similarItems.position
    });
    if (!selectedLink && similarItemClasses && selectedButton) {
      selectedButton.add(similarItemClasses);
    }
    if (similarLinkCollapsedClass && selectedLink) {
      selectedLink.add(similarLinkCollapsedClass);
    }

  };

  const setSimilarItemsCarousel = (selectedItemId, position, productPodRef, isSimilarItemButton) => {
    if (isSimilarItemButton) {
      handleButtonSelection(position, productPodRef);
    }
    const newSimilarItemsState = {};
    if (selectedItemId === similarItems.itemId && (position === similarItems.position)) {
      newSimilarItemsState.itemId = null;
      newSimilarItemsState.position = null;
      nodeRef.current = null;
      newSimilarItemsState.endOfRow = null;
      newSimilarItemsState.spliceIndex = null;
      newSimilarItemsState.closed = true;
    } else {
      newSimilarItemsState.itemId = selectedItemId;
      newSimilarItemsState.position = position;
      nodeRef.current = productPodRef.current;
      newSimilarItemsState.endOfRow = getIndexAtEndOfCurrentRow(productPodRef, position);
      newSimilarItemsState.spliceIndex = getSpliceIndex(selectedItemId, newSimilarItemsState.endOfRow);
      newSimilarItemsState.closed = false;
    }
    setSimilarItems(newSimilarItemsState);
  };

  const setClosedState = (closed) => {
    setSimilarItems({ closed });
    removeSimilarBtnClass();
  };

  const updatedChildren = [...productPods];

  const similarItemsOpen = !similarItems.closed
    && similarItems.spliceIndex !== null
    && similarItems.spliceIndex > -1;

  if (similarItemsOpen) {
    updatedChildren.splice(similarItems.spliceIndex + 1, 0,
      (
        <DetailsPod
          itemId={similarItems.itemId}
          reference={nodeRef}
          key={`DetailsPod-${similarItems.spliceIndex}`}
          scheme={scheme}
          setClosedState={setClosedState}
          detailsPodClassName={detailsPodClassName}
        />
      )
    );
  }

  const context = {
    handleProductPodExpansion: setSimilarItemsCarousel,
    setOverlayState: setClosedState
  };

  return (
    <ProductPodGroupContext.Provider value={context}>
      {updatedChildren}
    </ProductPodGroupContext.Provider>
  );
};

ProductPodGroup.displayName = 'ProductPodGroup';

ProductPodGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: arrayOf(object).isRequired,
  customScheme: string,
  DetailsPod: func,
  keyword: string,
  detailsPodClassName: string
};

ProductPodGroup.defaultProps = {
  customScheme: null,
  DetailsPod: CustomProductPodDetails,
  keyword: null,
  detailsPodClassName: ''
};

export { ProductPodGroup };
